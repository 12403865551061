import { ROUTES } from './constants';

const NavbarMenuItems = [
  {
    name: 'Home',
    icon: 'home',
    url: ROUTES.HOME,
    // isBookmarked: true
  },
  {
    name: 'CreateDocument',
    icon: 'description',
    roles: [],
    url: ROUTES.CREATE_DOCUMENT,
    // isBookmarked: true,
  },
  // {
  //   name: 'Reports',
  //   icon: 'summarize',
  //   group: true,
  //   roles: [],
  //   items: [
  //     {
  //       name: 'Booking',
  //       icon: 'date_range',
  //       url: '/reports/booking/'
  //     },
  //     {
  //       name: 'Incentive',
  //       icon: 'account_balance_wallet',
  //       url: '/reports/incentive/'
  //     },
  //     {
  //       name: 'Payout',
  //       icon: 'payments',
  //       url: '/reports/payout/'
  //     },
  //     {
  //       name: 'Enquiry',
  //       icon: 'forum',
  //       url: '/reports/enquiry/'
  //     },
  //   ]
  // },
  // {
  //   name: 'Operations',
  //   subHeading: true,
  // },
  // {
  //   divider: true,
  // },
  {
    name: 'Help & Support',
    icon: 'support_agent',
    roles: [],
    url: '/support/'
  },
];

export default NavbarMenuItems;
