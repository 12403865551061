class AuthService {
  #accessToken;
  constructor() {
    this.#accessToken = null
  }

  getAuthToken() {
    return this.#accessToken;
  }

  setAuthToken(token) {
    this.#accessToken = token;
  }
}
const authService = new AuthService();
export default authService;
