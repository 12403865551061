// API NAMES
export const ROUTES = {
  
  LOGIN: '/signin/',
  REGISTER: '/register/',
  PROFILE: '/my-account/profile/',
  FORGOT_PASSWORD: '/reset-password/',
  UNAUTHORIZED: '/unauthorized/',
  SERVER_DOWN: '/504/',
  MY_ACCOUNT: '/my-account/',
  PRIVACY_POLICY: '/privacy/',
  TERMS_OF_USE: '/terms-of-use/',
  CONTACT_US: '/contact-us/',
  ABOUT_US: '/about-us/',
  EMAIL_UNSUBSCRIPTION: '/subscriptions/email/',
}

export const LOGIN_COOKIE = 'c_ptls';
