// import Routes from 'core/routes/index.js';
// import { matchPath } from "react-router";

export const isLinkAccessable = (rolesAssigned = [], userRoles) => {
  if(rolesAssigned.length === 0){
    return true;
  }
  const matchedRoles = rolesAssigned.filter(value => userRoles.includes(value));

  if(matchedRoles.length > 0){
    return true
  }
  return false;
}

export function setCookie(cname, cvalue, exMins) {
  var d = new Date();
  d.setTime(d.getTime() + (exMins*60*1000));
  var expires = "expires="+d.toUTCString();  
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

// export function checkIsAuthRoute(url) {
//   let isAuthRoute = false;
//   for(let i=0; i < Routes.length; i++) {
//     const route = Routes[i];
//     // console.log('route',route);
//     if(route?.enableAuth === true && matchPath({path:route.path},url)){
//       isAuthRoute = true;
//       break;
//     }
//   }
//   return isAuthRoute;
// }

export function getTimeDiffInSec(date1, date2){
  // const diffSlots = {
  //   sec: 1000,
  //   miutes: 60,
  //   hours: 24,
  // }
  try{
    const d1 = new Date(date1);
    const d2 = new Date(date2);
    const diff = d1.getTime() - d2.getTime();
    return Math.round((diff/1000));
  }catch(err){
    return null;
  }
}

export function parseJwt(token) {
  try {
    return JSON.parse(atob(token.split(".")[1]));
  } catch (e) {
    return null;
  }
};
