import { AuthContext } from "core/context";
import React, { useContext } from "react"
import { Navigate, useLocation } from "react-router-dom";
import { isLinkAccessable } from "core/utils/common"; 
import AppLoading from 'core/components/appLoading';
import { buildBreadcrumbs } from "./breadcrumbs";
import { ROUTES } from "routes/constants";
// import { isValidBusinessId } from "utils";
// import LicenseError from "core/pages/licenseError";
// import { BlankPage } from "core/layouts";
// import VerificationError from "core/pages/VerificationError";

const AuthenticatedRoute = ({ children, rolesAssigned=[], routes, path, route}) => {
  const { isUserLoggedIn, data = {} } = useContext(AuthContext);
  // const { businesses } = useContext(AppContext);
  // const { businessId } = useParams();

  const { roles = [] } = data;
  const location = useLocation()

  if(!isUserLoggedIn) { 
    return <AppLoading /> 
  }

  // // After user login validate path businessId value with app api returned businesses 
  // if(isUserLoggedIn && businessId) { 
  //   const businessFound = isValidBusinessId(businesses, businessId);
  //   if(!businessFound)return <Navigate to='/error/' replace={true} />;

  //   // Pass verification required component 
  //   if(route.verificationRequired && !(businessFound?.isVerified)){
  //     return <BlankPage {...route.pageLayoutProps}><VerificationError /></BlankPage>
  //   }

  //   // Pass license component instead of selected component
  //   if(route.licenseRequired && !(businessFound?.isLicensed)){
  //     return <BlankPage {...route.pageLayoutProps}><LicenseError /></BlankPage>
  //   }
  // }

  // redirec to unauthorized page id role is invlaid
  if(rolesAssigned.length > 0 && !(isLinkAccessable(rolesAssigned, roles))){
    return <Navigate to={ROUTES.HOME} replace={true} />
  }

  const breadcrumbs = buildBreadcrumbs(routes, path, location);
  
  return React.cloneElement(children, {...{breadcrumbs}});
};

export default AuthenticatedRoute;
