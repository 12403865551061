import React, { useState, useContext, useEffect, useCallback } from 'react';  
import { Link, Grid, Paper, Stack } from '@mui/material';
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { AuthContext } from 'core/context';
import Register from './Register';
import { useLocation } from 'react-router-dom';
import SignIn from './SignIn';
import { ROUTES, LOGIN_COOKIE } from 'core/utils/constants';
import { logger } from 'core/utils/logger';
import CircleIcon from '@mui/icons-material/Circle';

export default function SignInSide() {
  logger.info('SignInSide mounting...');
  /* Hooks */
  const [toggleSignIn, setToggleSignIn] = useState(null);
  let navigate = useNavigate();
  const authContext = useContext(AuthContext);
  const location = useLocation()
  
  useEffect(() => {
    logger.debug('SignInSide mounted');

    if(authContext?.isUserLoggedIn || document.cookie.indexOf(LOGIN_COOKIE) >= 0){
      // navigate('/dashboard/', {replace: true})
      setTimeout(()=>navigate('/'), 100);
    }else{
      setToggleSignIn( location.pathname.indexOf(ROUTES.LOGIN) > -1 ? true : false);
    }
  }, [authContext, navigate, location]);

  const onSignupSuccess = useCallback(() => {
    logger.debug('SignInSide unmounted');
    setToggleSignIn(true);
  },[]);
  

  return typeof toggleSignIn === 'boolean' ? (
    <Grid container component="main"
      sx={{ 
        minHeight: '100vh',
        backgroundImage: `url(assets/images/page_bg.jpg)`,
        backgroundPosition: 'center', 
        backgroundSize: 'cover',
      }}
      flexDirection={{md:'row'}}
      justifyContent="center"
      alignItems="center"
    >
      {/* <CssBaseline /> */}
      <Grid container item xs={10} sm={9} md={11} lg={10} xl={8} elevation={24} component={Paper} 
        sx={{
          borderRadius: 0,
          baxShadow: 'rgb(0 0 0 / 10%) 0px 20px 25px, rgb(0 0 0 / 4%) 0px 10px 10px',
          minHeight: '600px'
        }}
      >
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6} textAlign="center" sx={{p:4}}>
            <img
              style={{ width: '140px', margin: '1rem' }}
              alt="Booktool Logo" src={`assets/images/logo.png`}
              className='login-logo'
            />
          {toggleSignIn ? <SignIn /> : <Register onSignupSuccess={onSignupSuccess} />}
          <Stack direction="row" columnGap={2} justifyContent="center" alignItems="center" mt={2} flexWrap="wrap" rowGap={1}>
            <Link target="_blank" component={RouterLink} to={ROUTES.ABOUT_US} fontSize={14} color="primary.light">About</Link>
            <CircleIcon sx={{fontSize: 4, color: 'primary.light'}} />
            <Link target="_blank" component={RouterLink} to={ROUTES.PRIVACY_POLICY} fontSize={14} color="primary.light">Privacy</Link>
            <CircleIcon sx={{fontSize: 4, color: 'primary.light'}} />
            <Link target="_blank" component={RouterLink} to={ROUTES.TERMS_OF_USE} fontSize={14} color="primary.light">Terms of use</Link>
            <CircleIcon sx={{fontSize: 4, color: 'primary.light'}} />
            <Link target="_blank" component={RouterLink} to={ROUTES.CONTACT_US} fontSize={14} color="primary.light">Contact us</Link>
          </Stack>
        </Grid>
        <Grid  item xs={0} sm={0} md={6} lg={6} xl={6}
          sx={{
            backgroundImage: `url(assets/images/info_banner.jpg)`,
            backgroundSize: 'cover'
          }}
        />
      </Grid>
    </Grid>
  ) : null;
}
