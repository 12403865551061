import React, { useMemo, Suspense, useCallback, useContext, useEffect, useState } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { Box, CssBaseline, Container, LinearProgress, Backdrop, } from '@mui/material';
import Header from 'core/components/header';
import ErrorBoundary from 'core/pages/error';
import TokenRefresh from 'core/components/tokenRefresh';
import appTheme from './theme';
import NavDrawer from 'core/components/drawer';
import Loader from 'core/components/Loader';
import { Outlet } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import { AppContext } from 'core/context';
import { useTheme } from '@mui/material/styles';
import { logger } from 'core/utils/logger';
import { menuItems } from 'core/components/drawer';

const AppLayout = ({navbar=true }) => {
  logger.info('App layout mounting...');
  
  const hideNavIcon = useMemo(() => menuItems.length === 0 ? false : navbar , [navbar]);
  const [showBackdrop, setShowBackdrop] = useState(false);
  const { isSideDrawerOpen, updateAppContext } = useContext(AppContext);
  const theme  = useTheme()
  const matches = useMediaQuery(theme.breakpoints.down('lg'));

  const handleClose = useCallback(() => {
    if(matches){
      updateAppContext({isSideDrawerOpen: false});
    }
  },[matches, updateAppContext]);

  useEffect(() => {
    if(matches && isSideDrawerOpen){
      setShowBackdrop(isSideDrawerOpen)
    }else{
      setShowBackdrop(false)
    }
  }, [isSideDrawerOpen, matches, updateAppContext]);

  useEffect(() => {
    logger.debug('App layout mounted');
    return () => {
    }
  }, []);

  return (
    <ThemeProvider theme={appTheme}>
      <Box 
        sx={{ 
          minHeight: '100vh', 
          // backgroundColor: appTheme.palette.primary.lighter,
          // backgroundImage: `url(${process.env.REACT_APP_PATH}/assets/images/page_bg.jpg)`,
          // backgroundPosition: 'center', 
          // backgroundSize: 'cover',
          display: 'flex'
        }}
      >
        <CssBaseline />
        <Loader />
        {navbar && <NavDrawer />}
        <Header hideNavIcon={!hideNavIcon} />
        <Box 
          component="main" 
          sx={{ 
            py: 2, 
            // mt: {xs: `${isSideDrawerOpen ? '102px' : 0}`, sm: `${isSideDrawerOpen ? '72px' : 0}`},
            // pt: {xs: `${isSideDrawerOpen ? 0 : '102px'}`, sm: `${isSideDrawerOpen ? 0 : '72px'}`}, 
            pt: '72px',
            // pt: {xs: '102px', sm: '72px'},
            flexGrow: 1, 
            maxHeight: {xs: `${isSideDrawerOpen ? '100vh' : '100%'}`, sm: `${isSideDrawerOpen ? '100vh' : '100%' }`}, 
            overflow: matches && isSideDrawerOpen ? 'hidden' : 'auto'
          }}
        >
          <Container maxWidth="xl" >
            <Backdrop
              sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer - 1 }}
              open={showBackdrop}
              onClick={handleClose}
            />
            <ErrorBoundary>
              <Suspense fallback={<Box sx={{ width: '100%', position: 'absolute', top: 68, left: 0, zIndex: 999999 }}><LinearProgress color="primary" sx={{height: 4}} /></Box>}>
                <TokenRefresh />
                <Outlet />
              </Suspense>
             </ErrorBoundary>
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
}
 
export default React.memo(AppLayout);
