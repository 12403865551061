import React, { useState, useContext, useEffect, useCallback, useMemo } from 'react';
import { AppBar, Grid, Avatar, Button, Divider, ListItemIcon, Stack, Typography, useMediaQuery} from '@mui/material';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MenuItem from '@mui/material/MenuItem';
import {Menu, Link} from '@mui/material';
// import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import { NavLink as RouteLink, useNavigate, useParams } from "react-router-dom";
import { AppContext, AuthContext } from 'core/context';
import { logoutUser } from 'core/services/auth';
import { ROUTES } from 'core/utils/constants';
import { useTheme } from '@mui/material/styles';
import { getBookMarkedMenuLinks } from 'utils';
import NavbarMenuItems from 'routes/navbarItems';

const MenuItemWithNoHover = styled(MenuItem)({
  '&:hover': {
    backgroundColor: 'transparent',
  },
});

// function ElevationScroll(props) {
//   const { children, window, } = props;
//   // Note that you normally won't need to set the window ref as useScrollTrigger
//   // will default to window.
//   // This is only being set here because the demo is in an iframe.
//   const trigger = useScrollTrigger({
//     disableHysteresis: true,
//     threshold: 0,
//     target: window ? window() : undefined,
//   });

//   return React.cloneElement(children, {
//     sx: {
//       zIndex: trigger ? 5000 : 5000,
//       boxShadow: trigger ? 4 : 0,
//       backgroundColor:  trigger ? 'rgba(234, 240, 240, .65)' : 'transparent',
//       backdropFilter: trigger ? 'blur(10px)': 'blur(0)', 
//     }
//   });
// }


const MenuAppBar = function(props) {

  const [anchorEl, setAnchorEl] = useState(null);
  const { isSideDrawerOpen, updateAppContext } = useContext(AppContext);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('lg'));
  const { isUserLoggedIn, data, userSignout, name, email } = useContext(AuthContext);
  const [prevBreakPointMatch, setPrevBreakPointMatch] = useState(matches);
  const navigate = useNavigate();
  let pathParams = useParams();
  
  // get bookmarked links
  const bookmarkedLinks = useMemo(() => getBookMarkedMenuLinks(NavbarMenuItems, pathParams), [pathParams]);
  
  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onMenuLinkClick = (url) => {
    handleClose()
    setTimeout(()=>navigate(url), 50);
  }

  const toggleNavbar = useCallback(() => {
    updateAppContext({
      isSideDrawerOpen: !isSideDrawerOpen
    });
  },[isSideDrawerOpen, updateAppContext])

  const handleSignout = () => {
    logoutUser().then(({response, error}) => {
      const { statusCode } = response;
      if(statusCode){
        userSignout()
      }
    });
  }

  useEffect(() => {
    if(matches && prevBreakPointMatch !== matches){
      updateAppContext({isSideDrawerOpen: false});
    }
    setPrevBreakPointMatch(matches);
  }, [matches,prevBreakPointMatch, setPrevBreakPointMatch, updateAppContext])
  
  return (
    // <ElevationScroll {...props}>
      <AppBar position="fixed" 
        color="appBar" 
        sx={{ 
          zIndex: (theme) => theme.zIndex.drawer + 1,
          // boxShadow: 0,
          // borderBottom: `${isSideDrawerOpen ? `1px solid ${theme.palette.primary.lighter1}` : 0}`,
          // backgroundColor:'rgba(234, 240, 240, .65)',
          // backdropFilter: 'blur(15px)', 
        }}
      >
        {/* <Container maxWidth="xl"> */}
          <Toolbar sx={{ p: 1,}}>
            <Grid container justifyContent="space-between" direction="row" alignItems="center" rowSpacing={.8} 
              sx={{py: .5}}
            >
      
            <Grid order={0} item xs={7} sm={3} md={3} lg={2} xl={2}>
              <Stack direction="row" alignItems="center">

              {props.hideNavIcon ? null : (
                <IconButton
                size="large"
                edge="start"
                color="primary"
                aria-label="menu"
                id="navMenubutton"
                sx={{ ml: 0, mr: '2px' }}
                onClick={toggleNavbar}
                >
                  <MenuIcon />
                </IconButton>
              )}
              <Link component={RouteLink} to="/" color="secondary">
                <Avatar
                  alt="Webook"
                  src={`assets/images/logo.png`}
                  sx={{ width: 126, height: 'auto' }}
                  variant="square"
                  />
              </Link>
                  </Stack>
            </Grid>
              {/* <Notifications /> */}
         
              {isUserLoggedIn && bookmarkedLinks?.length > 0 ? (
                <Grid item xs={12} sm={7} md={6} lg={8} xl={8} order={{xs: 2, sm: 1}} sx={{ borderTop: {xs: '1px solid #ddd', sm: 'none'}}} textAlign="center">
                  <Stack direction="row" columnGap={{xs: 3, sm: 2, md: 4}} alignItems="center" justifyContent="center">
                    {bookmarkedLinks.map(({name, url})=>(
                      <Link end key={url} className="header-menu-link" component={RouteLink} to={url} variant='body2' color="primary.main" underline="hover">
                        {name}
                      </Link>
                    ))}
                   
                  </Stack> 
                </Grid>
              ): null}
              {isUserLoggedIn && (
                <Grid item xs={5} sm={2} md={3} lg={2} xl={2} order={{xs: 1, sm: 2}} textAlign="right">
                  <IconButton
                    size="large"
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={handleMenu}
                    color="primary"
                  >
                    <AccountCircle />
                  </IconButton>
                  <Menu
                    id="menu-appbar"
                    anchorEl={anchorEl}  
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    PaperProps={{
                      elevation: 0,
                      sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 0,
                        '& .MuiAvatar-root': {
                          width: 32,
                          height: 32,
                          ml: -0.5,
                          mr: 1,
                        },
                        '&:before': {
                          content: '""',
                          display: 'block',
                          position: 'absolute',
                          top: 0,
                          right: 20,
                          width: 10,
                          height: 10,
                          bgcolor: 'background.paper',
                          transform: 'translateY(-50%) rotate(45deg)',
                          zIndex: 0,
                        },
                      },
                    }}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                  >
                    <MenuItem onClick={handleClose}>
                      <Stack>
                      <Typography variant="body1" sx={{ fontWeight: '600'}}>
                        {name}
                      </Typography>
                      <Typography variant="subtitle2" color="secondary" sx={{fontSize: '0.7rem'}} >
                        {data?.resellerEntityName || email}
                      </Typography>
                      </Stack>
                    </MenuItem>
                    <Divider />
                    {/* <MenuItem onClick={()=>onMenuLinkClick(ROUTES.PROFILE)} component={RouteLink}>
                      <ListItemIcon>
                        <AccountBoxIcon fontSize="small" />
                      </ListItemIcon>
                      Profile
                    </MenuItem> */}
                    <MenuItem onClick={()=>onMenuLinkClick(ROUTES.MY_ACCOUNT)} component={RouteLink}>
                      <ListItemIcon>
                        <AccountBoxIcon fontSize="small" />
                      </ListItemIcon>
                      My Account
                    </MenuItem>
                    <MenuItemWithNoHover>
                      <Button
                        fullWidth
                        variant="outlined"
                        onClick={handleSignout}
                        color="error"
                        sx={{textTransform: 'none', color: '#000'}}
                        startIcon={<PowerSettingsNewIcon />}
                      >
                        Logout
                      </Button>
                    </MenuItemWithNoHover>
                  </Menu>
                </Grid>
              )}
            </Grid>
          </Toolbar>
        {/* </Container> */}
      </AppBar>
    // </ElevationScroll>
  );
}

export default React.memo(MenuAppBar)
