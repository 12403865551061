import React, { Suspense } from 'react';
import { SnackbarProvider } from 'notistack';
import { AppContextProvider, AuthContextProvider } from 'core/context/';
import {  Button, Grid } from '@mui/material';
import ConfirmDialogProvider from 'core/context/ConfirmDialogProvider';
import { AppLayout, AppPublicLayout } from 'core/layouts';
import './App.scss';
// import Grow from 'core/components/snackbar/GrowTransition';

// import AppLoading from 'core/components/appLoading';
// import Hidden from 'Hidden';

function App({children}) {
  // add action to all snackbars
  const notistackRef = React.createRef();
  const onClickDismiss = key => () => { 
      notistackRef.current.closeSnackbar(key);
  }

  return (
    <AppContextProvider>
      <AuthContextProvider>
        <SnackbarProvider 
          hideIconVariant
          // TransitionComponent={Grow}
          maxSnack={3} 
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          sx={{maxWidth: 480}}
          preventDuplicate
          ref={notistackRef}
          autoHideDuration={4000}
          action={(key) => (
            <Button onClick={onClickDismiss(key)} size="small" sx={{color: "#fff"}}>
              Dismiss
            </Button>
          )}
        >  
          <ConfirmDialogProvider>
            {children}
          </ConfirmDialogProvider>
        </SnackbarProvider>
      </AuthContextProvider>
    </AppContextProvider>
  );
}

export default React.memo(App);

export const AuthLayout = React.memo(function() {
  return (
    <App>
      <AppLayout />
    </App>
  )
});

// export const AuthLayout = React.memo(function() {
//   const loaderData = useLoaderData();
//   console.log('loaderData', loaderData)
//   return (
//     <App>
//       {/* <Suspense fallback={<p>loader</p>}>
//         <Await
//           resolve={loaderData.app}
//         > */}
//           <AppLayout userData={loaderData} />
//         {/* </Await>
//       </Suspense> */}
//     </App>
//   )
// });

// export function appLoader() {
//   console.log('loader appLoader');
//   return getAppState();
//   // return x;
//   // return defer({app: await getAppState()});
// }

export const PublicLayout = React.memo(function() {
  return (
    <App>
      <Suspense
      fallback={
        <Grid container component="main"
          sx={{ 
            minHeight: '100vh',
            backgroundImage: `url(assets/images/page_bg.jpg)`,
            backgroundPosition: 'center', 
            backgroundSize: 'cover',
          }}
          flexDirection={{md:'row'}}
          justifyContent="center"
          alignItems="center"
        />
      }
    >
      <AppPublicLayout />
    </Suspense>
  </App>
);
});
