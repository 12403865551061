import { httpService, httpAuthService } from 'core/services/httpService';

const registerUser = ({data}) => {
  return httpService.post({url: '/register', data});
}

async function getAppState(data) {
  return httpService.get({url: '/app', ...data, backgroundRequest: true});
}

const sendSignUpOTP = ({data = {}}) => {
  return httpService.get({url:'/registration/otp', data});
}

async function signInUser({data, signal}) {
  return httpService.get({url: '/login', data, signal});
};


async function logoutUser() {
  return httpAuthService.get({url: '/logout'});
};

async function sendPasswordResetOTP({data}) {
  return httpService.get({url: '/forgot-password/otp', data });
};

async function resetPassword({data}){
  return httpService.post({url: '/reset-password-with-otp', data});
};

async function getNewAccessToken({signal}){
  return httpService.get({url: '/token', signal, backgroundRequest: true});
};


function loader(){
  return {abc: true};
}

export { getAppState, signInUser, logoutUser, resetPassword, sendPasswordResetOTP, sendSignUpOTP, registerUser, getNewAccessToken , loader};
