import { ROUTES as CORE_ROUTES} from 'core/utils/constants';

export const pathPrefix = '/b';

export const ROUTES = {
  ...CORE_ROUTES,
  HOME: `/home/`,
  SUPPORT: '/support/',
  CREATE_DOCUMENT: `/create-document/`,
  CREATE_DOCUMENT_TYPE: `/create-document/:docType`,
}
