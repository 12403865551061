import React, { useCallback, useContext, useEffect } from 'react';
import { Avatar, Box, CircularProgress, CssBaseline } from '@mui/material';
import { getAppState } from 'core/services/auth';
import { AuthContext, AppContext } from "core/context";
// import { useNavigate } from "react-router-dom";

const LoadingApp = () => {
  const { updateAuthContext } = useContext(AuthContext);
  const { updateAppContext } = useContext(AppContext);
  // let navigate = useNavigate();

  const getAppLoginStatus = useCallback((signal) => {
    getAppState({signal}).then(({response={}, error}) => {
      setTimeout(() => {
        const { statusCode, data} = response;
        if(statusCode){
          const { accessToken, name, email, businesses=[] } = data;
          const authData = { accessToken, name, email };
          updateAuthContext({isUserLoggedIn: true, ...authData});
          updateAppContext({businesses});
          return;
        } 
        // if(error){
        //   // updateAuthContext({isUserLoggedIn: false})
        //   // navigate('/signin/', {replace: true});
        // }
      }, 300);
    })
  }, [updateAuthContext, updateAppContext]);
  
  useEffect(() => {
    const controller = new AbortController();
    getAppLoginStatus(controller.signal);
    return () => {
      controller.abort();
    }
  }, [getAppLoginStatus]);
  

  return (
    <Box sx={{ minHeight: 'calc(100vh - 100px)', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <CssBaseline />
      <Box sx={{ position: 'relative' }}>
        <Avatar
          alt="Loading..."
          src="assets/images/favicon.png"
          sx={{ width: 40, height: 40 }}
        />
        <CircularProgress
          color="primary"
            size={68}
            sx={{
              color: '#0e1d34',
              position: 'absolute',
              top: -12,
              left: -12,
              zIndex: 1,
            }}
          />
          {/* <Typography sx={{mt: 2, ml: '-12px'}}>BookTool</Typography> */}
      </Box>
    </Box>
  );
}
 
export default React.memo(LoadingApp);
