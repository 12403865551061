import authService from 'core/services/authService';
import { format, startOfMonth } from 'date-fns';
import { generatePath, matchPath } from 'react-router-dom';

export const mapValueToId = (data = [], mapKey) => {
  if(Array.isArray(data) && data.length){
    return data.map((value, index) => ({...value, id: value[mapKey] || index}))
  }
  return data;
}

export const getConstDbFormat = (date = new Date()) => {
  return `${date.getDate().toString().padStart(2, '0')}-${(date.getMonth()+1).toString().padStart(2, '0')}-${date.getFullYear()}` 
};

export function getDBDateFormat(date) {
  return date ? format(date, 'dd-MM-yyyy') : date;
}

export const getFormattedDate = (filterDates) => {
  const [fromDate, toDate] = filterDates;
  if((fromDate && !toDate) || (fromDate && toDate && getDBDateFormat(fromDate) === getDBDateFormat(toDate))) {
    return `${format(fromDate,'dd MMM, yyyy')}`;
  } else if(fromDate && toDate){
    return `${format(fromDate,'dd MMM, yyyy')} - ${format(toDate,'dd MMM, yyyy')}`;
  } else {
    return 'Select Date';
  }
}

export function tConv24(time24) {
  var ts = time24;
  var H = +ts.substr(0, 2);
  var h = (H % 12) || 12;
  h = (h < 10)?("0"+h):h;  // leading 0 at the left for 1 digit hours
  var ampm = H < 12 ? " AM" : " PM";
  ts = h + ts.substr(2, 3) + ampm;
  return ts;
};

export const dataGridTablePlainStyles={
  '& .MuiDataGrid-columnSeparator': {opacity: '0 !important'},
  '& .MuiDataGrid-cell:focus': {outline: 'none'},
  '& .MuiDataGrid-columnHeader:focus': {outline: 'none'},
  '& .MuiDataGrid-footerContainer .MuiTablePagination-root': {color: 'typography.lighter'}
};


// Validate business id
export const isValidBusinessId = (businesses=[], businessId)=>{
  const businessesFound = businesses.filter(business=>business?.businessId === businessId);
  return (businessesFound?.length === 1 ? businessesFound[0] : false);
}

/******* Generate drawer link path with business id *****************/ 
function getDrawerMenuPathParamsNameArray(pathName) {
  if(!pathName)return [];
  const matched = matchPath(pathName, pathName);
  return Object.keys(matched.params);
}

export function generateDrawerLinksPath(menuItems, pathParams={}) {
  const urlParamsArray = Object.keys(pathParams);

  return menuItems.map((loc)=> {
    const menuLinkObj = {...loc};
    if(loc?.url){
      const pathParamsNameArray = getDrawerMenuPathParamsNameArray(menuLinkObj.url);

      // No keys means not depenendency so retrun path
      if(pathParamsNameArray?.length === 0) return menuLinkObj;
      
      //Check if params available from location param object 
      let allFound = pathParamsNameArray.every(name => urlParamsArray.includes(name));
      
      if(allFound)return {...menuLinkObj, url: generatePath(menuLinkObj.url, pathParams)};
      return null;
    }
     // filter group child paths
     if(menuLinkObj.group && menuLinkObj?.items?.length > 0 ){
      const childItems = menuLinkObj.items.map((locChild) => {
        
        if(locChild?.url){
          const childPathParamsNameArray = getDrawerMenuPathParamsNameArray(locChild.url);

          // No keys means not depenendency so retrun path
          if(childPathParamsNameArray?.length === 0) return locChild;

          //Check if params available from location param object 
          let allChildsFound = childPathParamsNameArray.every(name => urlParamsArray.includes(name));

          if(allChildsFound)return {...locChild, url: generatePath(locChild.url, pathParams)};
          return null;
        }
        return locChild;
      }) 
      // remove empty values
      .filter(e=>!!e)
      // Remove divider from first postion and last position
      .filter((e, i, arr)=> !((i===0 || i === arr.length-1) && e?.divider));

      // if atlease one child present add to current menu
      if(childItems?.length === 0)return null;
      menuLinkObj.items = childItems;
    }
    return menuLinkObj;
  })
  // remove empty values
  .filter(e=>!!e)
  // Remove divider from first postion and last position
  .filter((e, i, arr)=> !((i===0 || i === arr.length-1) && e?.divider));
}
/******* Generate drawer link path with business id *****************/ 

export function getBookMarkedMenuLinks(menuItems, pathParams={}) {
  const bookmarkedMenuItems = [];
  // get only bookmarked menu links
  menuItems.forEach(function(menuItem) {
    if(menuItem?.isBookmarked){
      bookmarkedMenuItems.push({...menuItem});
    }
    if(menuItem?.group && menuItem?.items?.length > 0){
      menuItem.items.forEach(function(childMenuItem) {
        if(childMenuItem?.isBookmarked){
          bookmarkedMenuItems.push({...childMenuItem});
        }
      });
    }
  });
  // generate actual path dynamic path
  return generateDrawerLinksPath(bookmarkedMenuItems, pathParams);
}

// Remove duplicate values from array of string
export function removeDuplicatesFromArray(array=[]) {
  return Array.isArray(array) ? [...new Set(array)] : array;
}

export function removeEmptyValuesFromArray(array=[]) {
  return Array.isArray(array) ? array.filter(e=>!!e) : array;
}

export function removeEmptyValuesFromObject(obj={}) {
  return Object.fromEntries(Object.entries(obj).filter(([_, v]) => (v != null && v !== '' && v !== undefined)));
}

export function removeEmptyValuesFromNestedObject(obj) {
  return Object.fromEntries(
    Object.entries(obj)
      .filter(([_, v]) => v != null)
      .map(([k, v]) => [k, v === Object(v) ? removeEmptyValuesFromNestedObject(v) : v])
  );
}

export function getTimeSolts() {
  const timeSlots = Array.from(new Array(24 * 2)).map(
    (_, index) =>{
        const hours = `${index < 20 ? '0' : ''}${Math.floor(index / 2)}`;
        const minutes = index % 2 === 0 ? '00' : '30';
        const value = `${hours}:${minutes}`;
        const label = tConv24(value);
      return {label, value}
    }
  );
  return timeSlots;
}

// check if t2 is greater than t1
export function compare24HourTime(t1, t2){
  const [t1Hours, t1Minues] = t1.split(':');
  const [t2Hours, t2Minues] = t2.split(':');

  var d1 = new Date();
  var d2 = new Date();
  d1.setHours(t1Hours, t1Minues, 0);
  d2.setHours(t2Hours, t2Minues, 0 );

  return (d2.getTime() - d1.getTime()) > 0;
}


export function getFormattedAddress(addressObj) {
  const addressArray = [];
  const addressKeys = ['buildingNo', 'street', 'landmark', 'area', 'city', 'state', 'pincode'];

  addressKeys.forEach((key) => {
    if(addressObj[key]){
      addressArray.push(addressObj[key])
    }
  })
  return addressArray.join(', ');
}

// Get common values from array
export const intersection = (arr1=[], arr2=[]) => arr1.filter(x => arr2.includes(x));

// The difference will output the elements from array 2 that are not in the array 2
export const difference = (arr1=[], arr2=[]) => arr1.filter(x => !arr2.includes(x));

// Get diffrence between Array A and Array B
export const symmetricalDifference = (arrA=[], arrB=[]) => arrA
                 .filter(x => !arrB.includes(x))
                 .concat(arrB.filter(x => !arrA.includes(x)));

export const currencyFormatter = new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' });
export const formatCurrency = (value) => currencyFormatter.format(value);

// 
export function toPreviousMonths(noOfMonths = 0) {

  const date = new Date();

  const dates = [];

  for (let i=noOfMonths - 1 ; i >= 0; i--) {
    const dateObj = {
      date: format(startOfMonth(new Date(date)), 'dd-MM-yyyy'),
      label: format(new Date(date), 'MMM-yyyy'),
    }
    dates.push(dateObj);
    date.setMonth(date.getMonth() - 1);
  }

  return dates.reverse();
}


// Create the function
export async function AddScript(url) {
  return new Promise((resolve) => {
    
    // resolve if document has script with same url
    if(document.querySelector(`script[src*='${url}']`)){
      return resolve();
    }
    // Add script otherwise
    const script = document.createElement('script');
    script.src = url;
    script.async = true;
    script.onload = script.readystatechange = () => resolve();
    script.crossorigin = "anonymous";
    document.body.appendChild(script);
  });
}

export function getLicenseInvoicePath(orderId) {
  const authToken = authService.getAuthToken();
  return authToken && orderId ? `${process.env.REACT_APP_API_ORIGIN}/s/secure/bl/inv/${orderId}.pdf?tkn=${authToken}` : '';
}

export function base64toBlob(base64Data, contentType) {
  contentType = contentType || '';
  var sliceSize = 1024;
  var byteCharacters = atob(base64Data);
  var bytesLength = byteCharacters.length;
  var slicesCount = Math.ceil(bytesLength / sliceSize);
  var byteArrays = new Array(slicesCount);

  for (var sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
      var begin = sliceIndex * sliceSize;
      var end = Math.min(begin + sliceSize, bytesLength);

      var bytes = new Array(end - begin);
      for (var offset = begin, i = 0; offset < end; ++i, ++offset) {
          bytes[i] = byteCharacters[offset].charCodeAt(0);
      }
      byteArrays[sliceIndex] = new Uint8Array(bytes);
  }
  return new Blob(byteArrays, { type: contentType });
}