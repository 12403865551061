import React from 'react';
import { Backdrop, Card, LinearProgress, Stack, Typography } from '@mui/material';
import { useAxiosLoader } from './AxiosLoader';

const Loader = () => {
  const [loading] = useAxiosLoader();
  // console.log('loading', loading);
  return(
    <Backdrop
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1300}}
      open={loading}
    >
      {/* <Box sx={{position: 'relative'}}>
        <Avatar
          alt="Booktool Loading..."
          src="/assets/images/favicon.png"
          sx={{ width: 40, height: 40 }}
          />
        <CircularProgress
          color="primary"
          size={68}
          sx={{
            color: '#0e1d34',
            position: 'absolute',
            top: -12,
            left: -12,
            zIndex: 1,
          }}
          />
        </Box> */}
        {/* <Typography  sx={{mt: 2, ml: '-3.1rem'}}>Processing your request</Typography> */}
        {/* <Card sx={{width: 180, p: 1, borderRadius: '4px', }}>
          <Stack direction="row" columnGap={2} alignItems="center">
            <CircularProgress
              color="primary"
              size={40}
              sx={{
                verticalAlign: 'middle'
              }}
            />
            <Typography variant='subtitle1'>Processing...</Typography>
          </Stack>
        </Card> */}

        <Card sx={{width:280, p: 1, borderRadius: '4px', }}>
          <Stack direction="column" columnGap={2} alignItems="center">
            <Typography variant='subtitle1'>Hold on...</Typography>
            <LinearProgress sx={{width: '100%', my: 1}}  /> 
          </Stack>
        </Card>

    </Backdrop>    
  );
};

export default React.memo(Loader);


export const ApiProgress = function ({styles={}}) {
  return (
    <LinearProgress color="secondary" sx={{height: 4, ...styles}} />
  )
};
