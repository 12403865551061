import React, { useCallback, useContext, useEffect } from "react"
import { parseJwt } from 'core/utils/common';
import { AuthContext } from "core/context";
import { getNewAccessToken } from "core/services/auth";

const TokenRefresh = () => {

  const { isUserLoggedIn, accessToken, updateAuthContext } = useContext(AuthContext);

  const fetchNewAccessToken = useCallback(() => {
    const controller = new AbortController();
    getNewAccessToken({signal: controller.signal}).then(({response={}}) => {
      const { statusCode, data } = response;
      if(statusCode){
        updateAuthContext({isUserLoggedIn: true, ...data});
        return;
      }
    });
    return controller;
  },[updateAuthContext],)
  

  useEffect(() => {
    let interval, controller;

    if(isUserLoggedIn){
      const decodedJWT = parseJwt(accessToken);
      const { iat, exp } = decodedJWT || {};
      // Proceed to get expiry time diff
      if(iat && exp){
        // 5 - reduced number of sec to get token beofre expiry
        const tokenExpiresInSec = (exp - iat) - 5
        // Get new Access Token every interval seconds in background
        interval = setTimeout(()=> {
          controller = fetchNewAccessToken();
        }, tokenExpiresInSec*1000)
      }
    }
  
    return () => {
      if(controller?.abort)controller.abort();
      clearInterval(interval);
    }
  }, [isUserLoggedIn, accessToken, fetchNewAccessToken ])

  return null;
}
 
export default React.memo(TokenRefresh);
