import React, { useEffect, useRef } from 'react';
import { Container } from '@mui/material';
import { Box } from '@mui/system';
import { logger } from 'core/utils/logger';
import { memo } from 'react';
import Header from 'core/components/header';

const BlankPublicPageWithHeader = React.memo(({title, subTitle, pageData, children, breadcrumbs}) => {
  logger.info('BlankPublicPageWithHeader layout mounting...');
  
  const pageRef = useRef(null);

  useEffect(() => {
    logger.debug('BlankPublicPageWithHeader layout mounted');
      return () => logger.debug('BlankPublicPageWithHeader layout unmounted');
  }, []);

  return (
    <Box sx={{ minHeight: '100vh'}}>
      <Header hideNavIcon={true} />
      <Box 
        component="main" 
        sx={{ 
          py: 2, 
          pt: '72px',
          flexGrow: 1, 
          maxHeight: '100vh', 
          overflow: 'auto',
        
        }}
      >
        <Container maxWidth="xl" >
          {React.cloneElement(children, {...{pageRef }, pageData: {...pageData}})}
        </Container>
      </Box>
    </Box>
  );
});
 
export default memo(BlankPublicPageWithHeader);
