import { useEffect, useMemo, useState } from 'react';
import { axiosAuthInstance, axiosInstance } from 'core/services/httpService';


export const useAxiosLoader = () => {

  const [counter, setCounter] = useState(0);
  const [backgroundRequestsCount, setBackgroundRequestsCount] = useState(0);

  const interceptors = useMemo(() => {

    const inc = (val=1) => setCounter(counter => counter + val);
    const dec = (val=1) => setCounter(counter => counter - val);
    //getUserSuggestionsByMobile
    return ({
      request: config => (inc(), config),
      response: response => (dec(), response),
      error: (error) => {
        if(backgroundRequestsCount === 0){
          dec();
        }else{
          setBackgroundRequestsCount(counter => counter - 1);
        }
        return (Promise.reject(error));
      },
    });
  }, [backgroundRequestsCount]); // create the interceptors
  
  useEffect(() => {
    // add request interceptors
    const authReqInterceptor = axiosAuthInstance.interceptors.request.use(function(request){
      if( !(request.isBackgroundRequest)){
        setCounter(counter => counter + 1);
      }else{
        setBackgroundRequestsCount((counter) => counter + 1);
      }
      return request;
    }, interceptors.error);

    // add response interceptors
    const authResInterceptor = axiosAuthInstance.interceptors.response.use(function(response){
      if(!(response.config.isBackgroundRequest)){
        setCounter(counter => counter - 1);
      }else{
        setBackgroundRequestsCount((counter) => counter - 1);
      }
      return response;
    }, interceptors.error);

      // add request interceptors
    const reqInterceptor = axiosInstance.interceptors.request.use(function(request){
      if(!request?.isBackgroundRequest){
        setCounter(counter => counter + 1);
      }else{
        setBackgroundRequestsCount((counter) => counter + 1);
      }
      return request;
    }, interceptors.error);

    // add response interceptors
    const resInterceptor = axiosInstance.interceptors.response.use(function(response){
      if(!response?.config?.isBackgroundRequest){
        setCounter(counter => counter - 1);
      }else{
        setBackgroundRequestsCount((counter) => counter - 1);
      }
      return response;

    }, interceptors.error);
    return () => {
      // remove all intercepts when done
      axiosAuthInstance.interceptors.request.eject(authReqInterceptor);
      axiosAuthInstance.interceptors.response.eject(authResInterceptor);

      axiosInstance.interceptors.request.eject(reqInterceptor);
      axiosInstance.interceptors.response.eject(resInterceptor);
    };
  }, [interceptors]);
  
  return [counter > 0];
};
