import { lazy } from "react";
import { Navigate } from "react-router-dom";
import { ROUTES } from './constants';
import { BlankPage, BlankWithPageTitle } from "core/layouts";

// Lazy imports 
const HomePage = lazy(() => import('pages/dashboard'));
const HelpAndSupport = lazy(() => import('pages/support'));
const CreateDocumentPage = lazy(() => import('pages/create-document'));

const AppRoutes = [
  {
    path: "/",
    exact: true,
    pageLayout: BlankPage,
    component: () => <Navigate to={ROUTES.HOME} replace={true} />
  },
  {
    name: 'Home',
    path: ROUTES.HOME,
    pageLayout: BlankPage,
    component: HomePage,
    enableAuth: true,
    pageLayoutProps: {
      title: 'Dashboard',
      subTitle: 'stati, etc...',
    },
  },
  {
    name: 'Help and Support',
    path: ROUTES.SUPPORT,
    enableAuth: true,
    pageLayout: BlankWithPageTitle,
    component: HelpAndSupport,
    pageLayoutProps: {
      hideBreadcrumb: true,
      title: 'Contact Us',
      subTitle: 'Please fill out the form below and we will get in touch with you.',
    },
  },
  {
    name: 'Create Document',
    path: ROUTES.CREATE_DOCUMENT,
    enableAuth: true,
    pageLayout: BlankWithPageTitle,
    component: CreateDocumentPage,
    pageLayoutProps: {
      hideBreadcrumb: true,
      title: 'Create Document',
      subTitle: 'Please fill out the form below and we will get in touch with you.',
    },
  },
  {
    name: 'Create Document',
    path: ROUTES.CREATE_DOCUMENT_TYPE,
    enableAuth: true,
    pageLayout: BlankWithPageTitle,
    component: CreateDocumentPage,
    pageLayoutProps: {
      hideBreadcrumb: true,
      title: 'Create Document',
      subTitle: 'Please fill out the form below and we will get in touch with you.',
    },
  },
];
export default AppRoutes;

// {
  //   name: 'Services',
  //   path: ROUTES.SERVICES,
  //   pageLayout: BlankPage,
  //   component: ServiceListPage,
  //   enableAuth: true,
  //   licenseRequired: true,
  //   verificationRequired: true,
  //   // pageLayoutProps: {
  //   //   title: 'Dashboard',
  //   //   subTitle: 'stati, etc...',
  //   // },
  // },
 
  // {
  //   name: 'Offers',
  //   path: ROUTES.OFFERS,
  //   pageLayout: BlankPage,
  //   component: OffersPage,
  //   enableAuth: true,
  //   licenseRequired: true,
  //   verificationRequired: true,
  //   pageLayoutProps: {
  //     pageData: {
  //       info: {
  //         title: 'Boost your revenue with offers',
  //         description: 'An effective way of promoting your business and increasing income. Let\'s create and share your offers.' 
  //       }
  //     },
  //     // title: 'Insights',
  //     // subTitle: 'stati, etc...',
  //   },
  // },
  // {
  //   name: 'Booking Gateway Fee Explained',
  //   path: ROUTES.BOOKING_GATEWAY_CHARGES_EXAMPLE,
  //   pageLayout: BlankPublicPageWithHeader,
  //   component: BookingChargesExamplePage,
  // },