import { createTheme } from '@mui/material/styles';

let theme = createTheme({
  // zIndex: {
  //   drawer: 1301,
  // },
  palette: {
    appBar: {
      main: '#eaf0f0'
    },
    primary: {
      light: '#3E4A5C',
      lighter: '#eaf0f0', // dce5f4
      lighter1: '#dce2e2',
      main: '#3498db',
      // main: '#0e1d34',
      dark: '#3E4A5C',
      // darker: '#091424',
      contrastText: '#ffffff',
      dullBlack: "#555555"
    },
    secondary: {
      light: '#ee6350',
      lighter: '#fdafa4',//ffeec5 
      main: '#C74634',
      dark: '#b81700',//ffac00
      darker: '#8a1100',//c78e00
      contrastText: '#ffffff',
    },
    // secondary: {
    //   light: '#F0CC71',
    //   lighter: '#eed598',//ffeec5 
    //   main: '#cb9b23',
    //   dark: '#eeaa00',//ffac00
    //   darker: '#966c03',//c78e00
    //   contrastText: '#ffffff',
    // },
    // secondary: {
    //   light: '#ED4B82',
    //   lighter: '#F8FF07',
    //   main: '#fd4a87',
    //   dark: '#e91e63',
    //   darker: '#F8B507',
    //   contrastText: '#ffffff',
    // },
    black: {
      main: '#212121',
      contrastText: '#ffffff',
      light: '#c6c6c6'
    },
    white: {
      main: '#ffffff',
      contrastText: '#212121',
    },
    typography: {
      main: '#212121',
      heading: '#3a3541de',
      light: '#555555',
      lighter: '#838282',
      dark: '#BDBDBD',
      contrastText: '#  ',
    }
  },
  shape: {
    borderRadius: 10
  },
  components: {
    MuiButton: {
      variants: [
        {
          props: { variant: 'bt-btn-light' },
          style: {
            textTransform: 'none',
          }
        },
        // {
        //   props: { variant: 'no-shadow' },
        //   style: {
        //     '&:hover': {
        //       boxShadow: 'none'
        //     },
        //   }
        // }
      ],
      styleOverrides: {
        root: {
          textTransform: 'none',
          boxShadow: 'none',
          borderRadius: 4,
          '&:hover': {
            boxShadow: 'none'
          },
          // '& label': {
          //   color: '#f7b405',
          // },
          // '& label.Mui-focused': {
          //   color: '#f7b405',
          // },
          // '& .MuiInput-underline:after': {
          //   borderBottomColor: '#f7b405',
          // },
          // '& .MuiOutlinedInput-root': {
          //   '& fieldset': {
          //     borderColor: '#f7b405',
          //   },
          //   '&:hover fieldset': {
          //     borderColor: '#f7b405',
          //   },
          //   '&.Mui-focused fieldset': {
          //     borderColor: '#f7b405',
          //   },
          // },
        },
      }
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        }
      }
    }
  },
  // shadows:{
  //   25: "rgb(61 71 82 / 20%) 0px 4px 20px",
  //   26: "rgb(0 0 0 / 10%) 0px 20px 25px, rgb(0 0 0 / 4%) 0px 10px 10px"
  // }
  // components: {
  //   MuiTextField: {
  //     styleOverrides: {
  //       root: {
  //         '& label': {
  //           color: '#f7b405',
  //         },
  //         '& label.Mui-focused': {
  //           color: '#f7b405',
  //         },
  //         '& .MuiInput-underline:after': {
  //           borderBottomColor: '#f7b405',
  //         },
  //         '& .MuiOutlinedInput-root': {
  //           '& fieldset': {
  //             borderColor: '#f7b405',
  //           },
  //           '&:hover fieldset': {
  //             borderColor: '#f7b405',
  //           },
  //           '&.Mui-focused fieldset': {
  //             borderColor: '#f7b405',
  //           },
  //         },
  //       },
  //     }
  //   }
  // }
});

export default theme;
