import React, { memo, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Drawer as BaseDrawer, Link, Toolbar, Box, useMediaQuery, ClickAwayListener, Avatar, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { AppContext } from 'core/context';
import NavbarMenuItems from 'routes/navbarItems';
import NavList from './NavList';
import { useTheme } from '@mui/material/styles';
import { Link as RouteLink, useParams } from 'react-router-dom';
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';
import { generateDrawerLinksPath, isValidBusinessId } from 'utils';

const drawerWidth = 280;
const defaultNavbarMenuItems = [];
const menuItems = [
  ...defaultNavbarMenuItems,
  ...NavbarMenuItems
];

const Drawer = styled(BaseDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      color: theme.palette.primary.light, 
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth, 
      marginLeft: `-${drawerWidth}px`,
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeIn,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('margin', {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: drawerWidth,
        // width: theme.spacing(7),
        // [theme.breakpoints.up('sm')]: {
        //   width: theme.spacing(9),
        // },
        marginLeft: 0,
      }),
    },
    // '& .Mui-selected': {
    //   color: theme.palette.secondary.main,
    //   // borderLeft: `6px solid ${theme.palette.secondary.main}`,
    //   // backgroundColor: theme.palette.primary.lighter,
    //   backgroundColor: theme.palette.primary.lighter,
    // },
    '& .Mui-selected .MuiListItemIcon-root': {
      color: theme.palette.secondary.main,
    },
    '& .MuiListItemButton-root.Mui-selected': {
      color: theme.palette.secondary.main,
      pointerEvents: 'none',
      // borderRight: `6px solid ${theme.palette.primary.main}`,
      backgroundColor: theme.palette.primary.lighter,
    },
    '& .MuiListItemButton-root.Mui-selected:hover': {
      backgroundColor: theme.palette.primary.lighter,
    },
    '& .MuiListItemButton-root:hover': {
      // color: theme.palette.primary.main,
      // borderRight: `6px solid ${theme.palette.primary.main}`,
      backgroundColor: theme.palette.primary.lighter1,
    },

  }),
);

const Navbar = () => {
  /* Hooks */
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.down('lg'));
  // const mdBelowMatches = useMediaQuery(theme.breakpoints.down('sm'));
  const { isSideDrawerOpen, businesses, updateAppContext } = useContext(AppContext);
  const [busineesInfo, setBusineesInfo] = useState(false);
  let pathParams = useParams();
 
  // Generate actual path for dynamic path
  const menuItemWithPath = useMemo(() => generateDrawerLinksPath(menuItems, pathParams), [pathParams]);

  // get business details
  useEffect(() => {
    const { businessId } = pathParams;
    if(businessId){
      const business = isValidBusinessId(businesses, businessId);
      if(business){
        setBusineesInfo({...business});
      }
    }else{
      setBusineesInfo(false);
    }
  }, [businesses, pathParams]);

  function onNavLinkClick() {
    if(matches){
      updateAppContext({
        isSideDrawerOpen: false
      });
    }
  }

  const handleClickAway = useCallback((e) => {
    const isMenuIcon = e.target.closest('#navMenubutton');
    if(!isMenuIcon && matches && isSideDrawerOpen){
      updateAppContext({
        isSideDrawerOpen: false
      });
    }
  },[matches, updateAppContext, isSideDrawerOpen]);

  return menuItemWithPath?.length > 0 ? (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Drawer variant="permanent" className="drawer-mobile" open={isSideDrawerOpen}>
        <Toolbar sx={{borderBottom: `1px solid ${theme.palette.primary.lighter}`, mt: 1}}>
          <Link component={RouteLink} to="/" color="secondary">
            <Avatar
              alt="Booktool"
              src={`assets/images/logo.png`}
              sx={{ width: 126, height: 'auto', }}
              variant="square"
            />
          </Link>
        </Toolbar>
        <SimpleBar style={{ height: 'calc(100vh - 72px)' }} >
          <Box 
            sx={{ pb: '60px', 
              // height: 'calc(100vh - 72px)',
              // overflow: 'auto', 
              mt: 1 //mdBelowMatches ? 5 : 0 
            }}
          >
            {busineesInfo? (
              <Box sx={{p:2, pb: 0, whiteSpace: 'break-spaces'}}>
                <Typography variant="h5" component="h6"  fontSize={24}  color={theme.palette.black.main}>{busineesInfo?.name}</Typography>
                <Typography variant="caption" gutterBottom component="p" >{busineesInfo?.formattedAddress}</Typography>
              </Box>
            ): null}
            <NavList menuItems={menuItemWithPath} isSideDrawerOpen={isSideDrawerOpen} onNavLinkClick={onNavLinkClick}/>
          </Box>
        </SimpleBar>
      </Drawer>
    </ClickAwayListener>
  ): null;
};
 
export default memo(Navbar);
export { menuItems };
