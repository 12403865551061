import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { ThemeProvider } from '@mui/material/styles';
import pgmTheme from 'core/layouts/theme';
import { grey } from '@mui/material/colors';
// import Zoom from '@mui/material/Zoom';

// const Transition = React.forwardRef(function Transition(props, ref) {
//   return <Zoom direction="up" ref={ref} {...props} unmountOnExit/>;
// });

const ConfirmationDialog = ({ open, options, onCancel, onConfirm, onClose, maxWidth }) => {
  const {
    title,
    description,
    content,
    confirmationText="Confirm",
    cancellationText = "Canel",
    dialogProps,
    confirmationButtonProps,
    cancellationButtonProps,
    titleProps,
    contentProps,
    allowClose,
  } = options;

  return (
    <ThemeProvider theme={pgmTheme}>
      <Dialog maxWidth={maxWidth || "xs"} fullWidth {...dialogProps} open={open} onClose={allowClose ? onClose : null} >
        {title && (
          <DialogTitle {...titleProps} fontSize={17} fontWeight={400}>{title}</DialogTitle>
        )}
        {content ? (
          <DialogContent {...contentProps}>
            {content}
          </DialogContent>
        ) : (
          description && (
            <DialogContent {...contentProps}>
              <DialogContentText >{description}</DialogContentText>
            </DialogContent>
          )
        )}
        <DialogActions>
          <Button  {...cancellationButtonProps} sx={{color: grey[700]}} onClick={onCancel}>
            {cancellationText}
          </Button>
          <Button  {...confirmationButtonProps} color="error" onClick={onConfirm}>
            {confirmationText}
          </Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
};

export default ConfirmationDialog;
