import React, {useState,useEffect, useCallback} from 'react';
import { useNavigate } from "react-router-dom";
import authService from 'core/services/authService';
import { ROUTES } from 'core/utils/constants';

const defaults = {
  isUserLoggedIn: false,
  authToken: null,
  accessToken: null,
  userId: null,
  data: {}
};

const AuthContext = React.createContext({...defaults});

const AuthContextProvider = (props) => {

  const [context, setContext] = useState({...defaults});
  let navigate = useNavigate();

  useEffect(() => {
    authService.setAuthToken(context?.accessToken || null);
  }, [context]);

  const updateAuthContext = useCallback((contextValues = {}) => {
    const data = {
      ...context,
      // authToken: contextValues.accessToken,
      ...contextValues
    }
    setContext(data);
  }, [context]);

  const userSignout = () => {
    setContext({...defaults});
    navigate(ROUTES.LOGIN, {replace: true});
  }

  return (
    <AuthContext.Provider value={{...context, updateAuthContext, userSignout}}>
      {props.children}
    </AuthContext.Provider>
  );
}
 
export default AuthContextProvider;
export { AuthContext };
