import axios from 'axios';
// import qs from 'qs';
import authService from 'core/services/authService';
import { ROUTES, LOGIN_COOKIE } from 'core/utils/constants';
import { setCookie } from 'core/utils/common';
import { httpLogger } from 'core/utils/logger';

class AxiosClass{
  isAuthRequest=false;
  isPublicRequest=false;
  constructor(auth, isPublic) {
    this.isAuthRequest = auth;
    this.isPublicRequest = isPublic;
  }

  // Get config defaults when creating the instance
  #getDefaultConfig() {
    const appOrigin = process.env.REACT_APP_API_ORIGIN || '';
    let baseUrl = process.env.REACT_APP_API_BASEPATH;
    if(this.isAuthRequest){
      baseUrl = process.env.REACT_APP_API_AUTH_BASEPATH
    }else if(this.isPublicRequest){
      baseUrl = process.env.REACT_APP_API_PATH
    }
    const defaultConfig = {
      baseURL: `${appOrigin}${baseUrl}`,
        // withCredentials: true
    }
    return defaultConfig
  }

  // Get config defaults when creating the instance
  getInstanceConfig(requestConfig={}, signal) {
    return {
      ...this.#getDefaultConfig(),
      ...requestConfig,
      ...(signal ? {signal}: {}),
    };
  }

  // Create Axios Instance with default config
  createAxiosInstance() {
    return axios.create(this.#getDefaultConfig());
  }
}

class httpServiceClass extends AxiosClass {
  #axiosInstance;
  #defaultConfig={isBackgroundRequest: false, headers: {}};
  #requestConfig={
    headers: {},
  };
  constructor({auth=false, isPublic=false}={}){
    super(auth, isPublic);
    this.#init();
  }
  // Initialize
  #init(){
    this.#axiosInstance = this.createAxiosInstance();
    this.#requestInterceptors();
    this.#responseInterceptors();
  }

  #resetRequestConfig(){
    this.#requestConfig = {...this.#defaultConfig};
  }

  // Add Authorization header with 'Bearer ' prefix
  // #addAuthorizationHeader() {
  //   const authToken = authService.getAuthToken();
  //   this.#requestConfig.headers = {
  //     ...(this.#defaultConfig.headers || {}),  
  //     'Authorization': `Bearer ${authToken}`,
  //   }
  // }

  // Attach data as query parameters
  #buildQueryParams(data={}) {
    if(Object.keys(data).length > 0){
      this.#requestConfig.params = {...data};
      // this.#requestConfig.paramsSerializer = function (params) {
      //   return qs.stringify(params, {arrayFormat: 'brackets'})
      // };
    }
  }

  // Add multipart/form-data content type header
  #addMultiPartHeader() {
    this.#requestConfig.headers = {
      ...this.#requestConfig.headers,  
      'Content-Type': "multipart/form-data; charset=utf-8; boundary=" + Math.random().toString().substring(2),
    }
  }

  // Build data as form data
  #buildFormData(object) {
    const formData = new FormData();
    Object.keys(object).forEach(key =>{
      if(key === '_filesArray'){
        const fileObj = object[key];
        const field =  fileObj.fieldName;
        const files =  fileObj.files;
        files.forEach((file)=>{
          formData.append(field, file);
        })
      }else{
        formData.append(key, object[key]);
      }
    });
    return formData;
  }
  
  #showProgress(pageRef) {
    if(pageRef?.current){
      pageRef.current?.classList.add('loading');
    }
  }
  
  #hideProgress(pageRef) {
    if(pageRef?.current){
      setTimeout(()=>pageRef.current?.classList.remove('loading'),0);
      
    }
  }

  // GET method
  async get({url='', data={}, config={}, signal, backgroundRequest = false, pageRef}) {
    try{
     
      this.#resetRequestConfig();
      // this.#showProgress(pageRef);

      // request specific config
      this.#requestConfig = {
        ...config, 
        url, 
        method: 'GET',
        isBackgroundRequest: !!pageRef || backgroundRequest,
        pageRef,
      };

      // query parameters if available
      this.#buildQueryParams(data);

      // Build config with request specific
      const requestConfig = this.getInstanceConfig(this.#requestConfig, signal)
      
      // Make call
      const res = await this.#axiosInstance(requestConfig);
      // this.#hideProgress(pageRef);
      // Manually throw Cancelled errors
      if(res?.message === "canceled"){
        throw new Error(res);
      }

      // Return response
      return {
        response:  res?.data || {},
        error: undefined,
      };

    }catch(err){
      // Catch if any api failures
      return Promise.reject(this.#httpCatchError(err));
    }
  }

  // POST method
  async post({url='', data={}, query, config={}, multipartFormData=false, signal, backgroundRequest = false, pageRef}) {
    try{
      this.#resetRequestConfig();
      // this.#showProgress(pageRef);

      // request specific config
      this.#requestConfig = {
        ...config, 
        url, 
        data: multipartFormData ? this.#buildFormData(data) : data, 
        method: 'POST',
        isBackgroundRequest: !!pageRef || backgroundRequest,
        pageRef
      };
      
      // query parameters if available
      this.#buildQueryParams(query);
      
      // If multipartFormData set to TRUE, data sent as multipart/form-data
      if(multipartFormData){
        this.#addMultiPartHeader();
      }
      
      // Build config with request specific
      const requestConfig = this.getInstanceConfig(this.#requestConfig, signal)

      // Make call
      const res = await this.#axiosInstance(requestConfig);
      // this.#hideProgress(pageRef);

      // Return response
      return {
        response:  res?.data || {},
        error: undefined,
      };

    }catch(err){
      // Catch if any api failures
      return Promise.resolve(this.#httpCatchError(err));
    }
  }

  // Handle api failures
  #httpCatchError(err){
    // Http logger
    const logMessage ={type: 'http:Catch', ...err};
    httpLogger.error(logMessage);

    const res = err?.response;
    if(err.message === 'canceled'){
      throw new Error(err);
    }
    return {
      response: undefined,
      error: res?.data?.error || (err.message),
    };
  }

  #commonLogMsg(req) {
    const { method, isBackgroundRequest, url, baseURL } = req;
    const msg = {method, url: `${baseURL}${url}`, isBackgroundRequest, };
    return msg;
  }

  // Request interceptors
  #requestInterceptors(){
    this.#axiosInstance.interceptors.request.use((req) => {
      
      // Show Progress bar
      this.#showProgress(req.pageRef);

      // Add Authentication Header
      if(this.isAuthRequest){ 
        const authToken = authService.getAuthToken();
        req.headers = {...req.headers, 'Authorization': `Bearer ${authToken}`}
      }

      // Http logger
      const logMessage ={ type: 'http:Request Sent', ...(this.#commonLogMsg(req))};
      httpLogger.info(logMessage);

      return req;
    });
  }

  // Response interceptors
  #responseInterceptors(){
    this.#axiosInstance.interceptors.response.use(
      (res) => {
        this.#hideProgress(res.config.pageRef);

        // Http logger
        const logMessage ={type: 'http:Response Received', statu: res.status, ...(this.#commonLogMsg(res.config))};
        httpLogger.info(logMessage);

        return res;
      },
      (error) => {
        // Trow errr again (may be need for some other catch)
        if(error?.response?.config?.pageRef){
          this.#hideProgress(error.response.config.pageRef);
        }

        // Http logger
        if(error?.response?.config){
          const logMessage ={type: 'http:Response Received', statu: error.response.status, ...(this.#commonLogMsg(error.response.config))};
          httpLogger.error(logMessage);
        }
        // Do something with response error
        if(error?.response?.status === 401) {
          // Delete cookie is required to avoid infinite page redirects between signin and dashboard
          setCookie(LOGIN_COOKIE, '', 0);
          window.location.href=`${process.env.PUBLIC_URL}${ROUTES.LOGIN}`;
        }
        if(error?.response?.status === 504){
          // Delete cookie is required to avoid infinite page redirects between signin and dashboard
          // setCookie(LOGIN_COOKIE, '', 0); 
          window.location.href=`${process.env.PUBLIC_URL}${ROUTES.SERVER_DOWN}`;
          return;
        }
        // process cancelled error differently 
        // if(error.message === 'canceled'){
        //   return Promise.reject(error);
        // }
        return Promise.reject(error);
      }
    );
  }

  getAxiosInstance() {
    return this.#axiosInstance;
  }
}

const httpService = new httpServiceClass();
const httpAuthService = new httpServiceClass({auth: true});
const httpPublicService = new httpServiceClass({isPublic: true});
const axiosInstance = httpService.getAxiosInstance();
const axiosAuthInstance = httpAuthService.getAxiosInstance();

export { httpService, httpAuthService, axiosAuthInstance, axiosInstance, httpPublicService };
